import BigNumber from 'bignumber.js'
import get from 'lodash.get'

import { ApprovalContext, TokenSpenders, TokenV3, TradeformType } from '../../model'
import { getGasBufferForNetwork, isNativeTokenForNetwork } from '../../utils'

const DEFAULT_DECIMALS = 0

export const calculateValue = (
  incPercentValue: number,
  tokenFrom: TokenV3,
  userBalance: BigNumber
): { fixedValue: string; percent: number } | undefined => {
  const percent = incPercentValue
  const gasBuffer = isNativeTokenForNetwork(tokenFrom)
    ? getGasBufferForNetwork(tokenFrom.network)
    : new BigNumber(0)
  /*
      after clicking percent button check out how much remains in the wallet
      if this value is enough for transaction fees, return userBalance*buttonPercent
      in other case userBalance*buttonPercent - gasBuffer
    */
  let value = new BigNumber(0)
  const balanceWithoutGasBuffer = userBalance.minus(gasBuffer)
  const percentValue = balanceWithoutGasBuffer.times(percent)

  value = percentValue.gt(0) ? percentValue : new BigNumber(0)

  const fixedValue = value.toFixed()

  return { fixedValue, percent }
}

export const getSellAmount = (
  fromAmount: string,
  decimals?: number,
  value?: BigNumber
): BigNumber => {
  if (value !== undefined) {
    return value.times(10 ** (decimals || DEFAULT_DECIMALS))
  }

  return new BigNumber(fromAmount).times(10 ** (decimals || DEFAULT_DECIMALS))
}

export const FormVariants: Record<string, TradeformType> = {
  market: 'market',
  limit: 'limit',
}

export const formVariantsValues: { title: string; value: TradeformType; tooltip?: string }[] = [
  {
    title: 'Market',
    value: FormVariants.market,
  },
  {
    title: 'Limit',
    value: FormVariants.limit,
    tooltip:
      'Learn more about <a href="https://docs.dex.guru/general/features/limit-orders" target="_blank" rel="noreferrer noopener">Limit Orders</a> on DexGuru',
  },
]

export const getSpenderAndAllowance = (
  formVariant: TradeformType,
  tokenFrom: TokenV3
): { spender: TokenSpenders; approvalContext?: ApprovalContext } => {
  const spender = formVariant === FormVariants.limit ? TokenSpenders.oneInch : TokenSpenders.zeroX
  const approvalContext = get(tokenFrom, spender)
  return { spender, approvalContext }
}
