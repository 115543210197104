import BigNumber from 'bignumber.js'

import { TokenV3, TokenWithApprovalContext } from '../../model'
import { WRONG_NETWORK_ERROR } from './constants'

/* eslint-disable no-unused-vars */
export enum FormStateTypes {
  NO_NETWORK = 'NO_NETWORK',
  NO_NETWORK_LOADING = 'NO_NETWORK_LOADING',
  WRONG_NETWORK = 'WRONG_NETWORK',
  WRONG_NETWORK_LOADING = 'WRONG_NETWORK_LOADING',
  WRONG_NETWORK_DESKTOP = 'WRONG_NETWORK_DESKTOP',
  LOADING = 'LOADING',
  READY = 'READY',
  NOT_APPROVED = 'NOT_APPROVED',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  OK = 'OK',
  APPROVE_IN_PROGRESS = 'APPROVE_IN_PROGRESS',
  TXN_IN_PROGRESS = 'TXN_IN_PROGRESS',
  ERROR = 'ERROR',
  INIT = 'INIT',
  TXN_PREPARE = 'TXN_PREPARE',
}

export enum ActionTypes {
  ChangeAmount = 'change@amount',
  ChangeLimitOrderRate = 'change@limit-order-rate',
  ChangeState = 'change@state',
  ChangeBalance = 'change@balance',
  SetStateLoading = 'set@state-loading',
  UpdateToken = 'update@token',
  SetFocus = 'set@focus',
  SetDelta = 'set@delta',
}
/* eslint-enable no-unused-vars */

export type Action =
  | ChangeAmount
  | ChangeLimitOrderRate
  | ChangeBalance
  | UpdateToken
  | SetFocus
  | SetStateLoading
  | ChangeState
  | SetDelta

export interface SetDelta {
  type: ActionTypes.SetDelta
  isDeltaError: boolean
}
export interface ChangeAmount {
  type: ActionTypes.ChangeAmount
  amountFrom: BigNumber
}
export interface ChangeLimitOrderRate {
  type: ActionTypes.ChangeLimitOrderRate
  limitOrderRate: BigNumber | undefined
}

export interface ChangeBalance {
  type: ActionTypes.ChangeBalance
  balance: BigNumber
}

export interface UpdateToken {
  type: ActionTypes.UpdateToken
  tokens: { tokenFrom: TokenWithApprovalContext; tokenTo?: TokenV3 }
}

export interface SetStateLoading {
  type: ActionTypes.SetStateLoading
  tokens: { tokenFrom: TokenWithApprovalContext; tokenTo: TokenV3 }
  state: FormStateTypes
}

export interface SetFocus {
  type: ActionTypes.SetFocus
  isFocus: boolean
}

export interface ChangeState {
  type: ActionTypes.ChangeState
  state: FormStateTypes
  addon?: Partial<FormState>
}

export interface FormState {
  name: FormStateTypes
  amountFrom: BigNumber
  limitOrderRate: BigNumber | undefined
  userBalance: BigNumber
  tokenFrom: TokenWithApprovalContext | null
  tokenTo: TokenV3 | null
  error: string
  showError: boolean
  isLoading: boolean
  isFocus: boolean
  isDeltaError: boolean
  txnAction: string | null
  tokenAction: string | null
  balanceAction: string | null
  estimateAction: string | null
  gasPriceAction: boolean | null
  inheritedValues: string[]
  cashAmount: BigNumber
}

type FormStateKeys = keyof FormState

const defaultActionState: FormState = {
  name: FormStateTypes.INIT,
  amountFrom: new BigNumber(0),
  userBalance: new BigNumber(0),
  error: '',
  showError: false,
  isFocus: false,
  isDeltaError: false,
  tokenTo: null,
  tokenFrom: null,
  txnAction: null,
  tokenAction: null,
  balanceAction: null,
  estimateAction: null,
  gasPriceAction: null,
  inheritedValues: [],
  isLoading: false,
  cashAmount: new BigNumber(0),
  limitOrderRate: undefined,
}

export const desktopFormStates = {
  [FormStateTypes.NO_NETWORK]: {
    ...defaultActionState,
    name: FormStateTypes.NO_NETWORK,
    error: 'No Network',
    showError: false,
    inheritedValues: ['amountFrom', 'userBalance', 'limitOrderRate'],
  },
  [FormStateTypes.NO_NETWORK_LOADING]: {
    ...defaultActionState,
    name: FormStateTypes.NO_NETWORK_LOADING,
    amountFrom: new BigNumber(0),
    userBalance: new BigNumber(0),
    txnAction: 'clear',
    tokenAction: 'clear',
    balanceAction: 'clear',
    estimateAction: 'getEstimate',
  },
  [FormStateTypes.WRONG_NETWORK]: {
    ...defaultActionState,
    name: FormStateTypes.WRONG_NETWORK,
    error: WRONG_NETWORK_ERROR,
    showError: false,
    inheritedValues: ['amountFrom', 'userBalance', 'limitOrderRate'],
  },
  [FormStateTypes.WRONG_NETWORK_LOADING]: {
    ...defaultActionState,
    name: FormStateTypes.WRONG_NETWORK_LOADING,
    amountFrom: new BigNumber(0),
    userBalance: new BigNumber(0),
    error: 'Wrong Network', // ?
    txnAction: 'clear',
    balanceAction: 'clear',
    estimateAction: 'getEstimate',
  },
  [FormStateTypes.WRONG_NETWORK_DESKTOP]: {
    ...defaultActionState,
    name: FormStateTypes.WRONG_NETWORK_DESKTOP,
    amountFrom: new BigNumber(0),
    userBalance: new BigNumber(0),
    error: 'Wrong Network', // ?
    txnAction: 'clear',
    balanceAction: 'clear',
    estimateAction: 'getEstimate',
    inheritedValues: ['amountFrom', 'userBalance', 'limitOrderRate'],
  },
  [FormStateTypes.LOADING]: {
    ...defaultActionState,
    name: FormStateTypes.LOADING,
    isLoading: true,
    amountFrom: new BigNumber(0),
    userBalance: new BigNumber(0),
    txnAction: 'clear',
    tokenAction: 'checkAllowance',
    balanceAction: 'getBalance',
    estimateAction: 'getEstimate',
    inheritedValues: ['cashAmount', 'limitOrderRate'],
  },
  [FormStateTypes.READY]: {
    ...defaultActionState,
    name: FormStateTypes.READY,
    isLoading: false,
    inheritedValues: [
      'amountFrom',
      'userBalance',
      'tokenTo',
      'tokenFrom',
      'cashAmount',
      'limitOrderRate',
    ],
  },
  [FormStateTypes.NOT_APPROVED]: {
    ...defaultActionState,
    name: FormStateTypes.NOT_APPROVED,
    gasPriceAction: true,
    inheritedValues: [
      'amountFrom',
      'userBalance',
      'tokenTo',
      'tokenFrom',
      'cashAmount',
      'limitOrderRate',
    ],
  },
  [FormStateTypes.INSUFFICIENT_BALANCE]: {
    ...defaultActionState,
    name: FormStateTypes.INSUFFICIENT_BALANCE,
    error: 'Insufficient balance',
    inheritedValues: [
      'amountFrom',
      'userBalance',
      'tokenTo',
      'tokenFrom',
      'cashAmount',
      'limitOrderRate',
    ],
  },
  [FormStateTypes.OK]: {
    ...defaultActionState,
    name: FormStateTypes.OK,
    inheritedValues: [
      'amountFrom',
      'userBalance',
      'tokenTo',
      'tokenFrom',
      'cashAmount',
      'limitOrderRate',
    ],
  },
  [FormStateTypes.APPROVE_IN_PROGRESS]: {
    ...defaultActionState,
    name: FormStateTypes.APPROVE_IN_PROGRESS,
    isLoading: true,
    inheritedValues: [
      'amountFrom',
      'userBalance',
      'tokenTo',
      'tokenFrom',
      'cashAmount',
      'limitOrderRate',
    ],
  },
  [FormStateTypes.TXN_IN_PROGRESS]: {
    ...defaultActionState,
    name: FormStateTypes.TXN_IN_PROGRESS,
    isLoading: true,
    inheritedValues: [
      'amountFrom',
      'userBalance',
      'tokenTo',
      'tokenFrom',
      'cashAmount',
      'limitOrderRate',
    ],
  },
  [FormStateTypes.TXN_PREPARE]: {
    ...defaultActionState,
    name: FormStateTypes.TXN_PREPARE,
    isLoading: true,
    estimateAction: 'getEstimateWithGas',
    inheritedValues: [
      'amountFrom',
      'userBalance',
      'tokenTo',
      'tokenFrom',
      'cashAmount',
      'limitOrderRate',
    ],
  },
  [FormStateTypes.ERROR]: {
    ...defaultActionState,
    name: FormStateTypes.ERROR,
    error: '0x api estimation error',
    inheritedValues: [
      'amountFrom',
      'userBalance',
      'tokenTo',
      'tokenFrom',
      'cashAmount',
      'limitOrderRate',
    ],
  },
  [FormStateTypes.INIT]: {
    ...defaultActionState,
    name: FormStateTypes.INIT,
    amountFrom: new BigNumber(0),
    userBalance: new BigNumber(0),
    isLoading: true,
    txnAction: 'clear',
    tokenAction: 'clear',
  },
}

export const initState = {
  ...desktopFormStates.INIT,
}

export const mergeStates = (oldState: FormState, newState: FormState): FormState => {
  const keys = Object.keys(oldState) as FormStateKeys[]

  return keys.reduce<FormState>(
    (acc: FormState, key: FormStateKeys) => {
      if (!newState?.inheritedValues?.includes(key)) {
        acc = { ...acc, [key]: newState[key] }
      }

      if (key === 'inheritedValues') {
        const inheritedValues = acc.inheritedValues.reduce<Partial<FormState>>(
          (acc: Partial<FormState>, key: string) => {
            acc = { ...acc, [key]: oldState[key as FormStateKeys] }
            return acc
          },
          {}
        ) as Partial<FormState>

        acc = { ...acc, ...inheritedValues }
      }

      return acc
    },
    { ...oldState }
  ) as FormState
}

export const formReducer = (state: FormState, action: Action): FormState => {
  switch (action.type) {
    case ActionTypes.ChangeAmount: {
      let name = FormStateTypes.READY
      if (
        state.name === FormStateTypes.NO_NETWORK_LOADING ||
        state.name === FormStateTypes.WRONG_NETWORK ||
        state.name === FormStateTypes.WRONG_NETWORK_DESKTOP ||
        state.name === FormStateTypes.NO_NETWORK ||
        state.name === FormStateTypes.NOT_APPROVED
      ) {
        name = state.name
      }

      const tokenFrom = { ...state.tokenFrom, tokenApproveError: null } as TokenWithApprovalContext

      return {
        ...state,
        cashAmount: state.amountFrom,
        amountFrom: action.amountFrom,
        name,
        error: '',
        showError: false,
        tokenFrom,
      }
    }
    case ActionTypes.ChangeLimitOrderRate: {
      let name = FormStateTypes.READY
      if (
        state.name === FormStateTypes.NO_NETWORK_LOADING ||
        state.name === FormStateTypes.WRONG_NETWORK ||
        state.name === FormStateTypes.WRONG_NETWORK_DESKTOP ||
        state.name === FormStateTypes.NO_NETWORK ||
        state.name === FormStateTypes.NOT_APPROVED
      ) {
        name = state.name
      }

      const tokenFrom = { ...state.tokenFrom, tokenApproveError: null } as TokenWithApprovalContext

      return {
        ...state,
        limitOrderRate: action.limitOrderRate,
        name,
        error: '',
        showError: false,
        tokenFrom,
      }
    }
    case ActionTypes.ChangeBalance: {
      return {
        ...state,
        userBalance: action.balance,
      }
    }
    case ActionTypes.ChangeState: {
      const key = action.state
      const addon = action.addon || {}
      const stateProps = desktopFormStates[key] as FormState

      return { ...mergeStates(state, stateProps), ...addon }
    }
    case ActionTypes.SetStateLoading: {
      const stateProps = desktopFormStates[FormStateTypes.LOADING]

      return { ...mergeStates(state, stateProps), ...action.tokens }
    }
    case ActionTypes.UpdateToken: {
      return { ...state, ...action.tokens }
    }
    case ActionTypes.SetFocus: {
      return { ...state, isFocus: action.isFocus }
    }
    case ActionTypes.SetDelta: {
      return { ...state, isDeltaError: action.isDeltaError }
    }
    default:
      return { ...state }
  }
}
